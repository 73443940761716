import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getEventStatusString } from '../../lib/models/Events';
import { formatDate } from '../../lib/utils/dateutils';

class EventView extends PureComponent {
  static propTypes = {
    event: PropTypes.object.isRequired
  };

  state = {
    error: false
  };

  left_box_classes = 'mb-3 col-12 col-md-8 col-lg-6';
  event_table_left_classes = 'col-12 col-md-6 col-lg-4';
  event_table_right_classes = 'col-12 col-md-6 col-lg-8';

  render() {
    const { event } = this.props;
    return (
      <div>
        <div className="row">
          <div className={this.left_box_classes}>
            <div className="card border-primary">
              <div className="card-header bg-primary text-white">
                <strong>Dettaglio</strong>
              </div>
              <div className="card-body">
                <dl className="row">
                  <dt className={this.event_table_left_classes}>Società responsabile:</dt>
                  <dd className={this.event_table_right_classes}>{event.partner_description}</dd>
                  <dt className={this.event_table_left_classes}>Titolo</dt>
                  <dd className={this.event_table_right_classes}>{event.title}</dd>
                  <dt className={this.event_table_left_classes}>Tipologia:</dt>
                  <dd className={this.event_table_right_classes}>{event.event_type_description}</dd>
                  <dt className={this.event_table_left_classes}>Responsabile:</dt>
                  <dd className={this.event_table_right_classes}>{event.responsible_account_name || ''}</dd>
                  <dt className={this.event_table_left_classes}>Indirizzo</dt>
                  <dd className={this.event_table_right_classes}>
                    <div style={{ whiteSpace: 'pre' }}>{event.address}</div>
                  </dd>
                  <dt className={this.event_table_left_classes}>Data:</dt>
                  <dd className={this.event_table_right_classes}>
                    {formatDate({ date: new Date(event.due_date), doubleDigit: true })}
                  </dd>
                  <dt className={this.event_table_left_classes}>Orario di inizio:</dt>
                  <dd className={this.event_table_right_classes}>{event.scheduled_hour || '-'}</dd>
                  <dt className={this.event_table_left_classes}>Durata (in ore):</dt>
                  <dd className={this.event_table_right_classes}>{event.duration ? event.duration : ''}</dd>
                  <dt className={this.event_table_left_classes}>Stato:</dt>
                  <dd className={this.event_table_right_classes}>{getEventStatusString(event.status)}</dd>
                  <dt className={this.event_table_left_classes}>Offline:</dt>
                  <dd className={this.event_table_right_classes}>{!!event.offline ? 'SI' : 'NO'}</dd>
                </dl>
              </div>
            </div>
          </div>

          <div className={this.left_box_classes}>
            <div className="card border-primary">
              <div className="card-header bg-primary text-white">
                <strong>Note</strong>
              </div>
              <div className="card-body">
                <dl className="row">
                  <dt className={this.event_table_left_classes}>Note tecniche:</dt>
                  <dd className={this.event_table_right_classes}>
                    <div style={{ whiteSpace: 'pre' }}>{event.technical_notes}</div>
                  </dd>
                  <dt className={this.event_table_left_classes}>Note generiche:</dt>
                  <dd className={this.event_table_right_classes}>
                    <div style={{ whiteSpace: 'pre' }}>{event.generic_notes}</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className={this.left_box_classes}>
            <div className="card border-primary">
              <div className="card-header bg-primary text-white">
                <strong>Dati Cliente</strong>
              </div>
              <div className="card-body">
                <dl className="row">
                  <dt className={this.event_table_left_classes}>Nome del referente principale:</dt>
                  <dd className={this.event_table_right_classes}>{event.main_referent_name}</dd>
                  <dt className={this.event_table_left_classes}>Contatto del referente principale:</dt>
                  <dd className={this.event_table_right_classes}>{event.main_referent_email}</dd>
                  <dt className={this.event_table_left_classes}>Nome del referente secondario:</dt>
                  <dd className={this.event_table_right_classes}>{event.secondary_referent_name}</dd>
                  <dt className={this.event_table_left_classes}>Contatto del referente secondario:</dt>
                  <dd className={this.event_table_right_classes}>{event.secondary_referent_email}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventView;
